body {
    background-color: #313131;

    @media (max-width: 768px) {
        font-size: 12px;
    }
}

.custom-navbar {
    background-color: #343a40 !important;
}

.poker-table {
    margin-top: 15px;
    padding: 5px;
    background-image: url('../public/assets/images/poker_table_green.png');
    background-repeat: no-repeat;
    background-size: 100%;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.pokerCard {
    width: 45px;
    height: 65px;
    background-image: url('../public/assets/images/card_top_red.png');
    background-repeat: no-repeat;
    background-size: 90%;
}

.pokerCardForPicker {
    width: 80px;
    height: 100px;
    background-image: url('../public/assets/images/card_top_red.png');
    background-repeat: no-repeat;
    background-size: 90%;
}

.cardOne {
    width: 50px;
    height: 70px;
    margin-left: 20%;
    background-image: url('../public/assets/images/card_top_red.png');
    background-repeat: no-repeat;
    background-size: 90%;

    @media (max-width: 990px) {
        width: 40px;
        height: 60px;
    }
}

.cardTwo {
    width: 50px;
    height: 70px;
    margin-left: -2%;
    background-image: url('../public/assets/images/card_top_red.png');
    background-repeat: no-repeat;
    background-size: 90%;

    @media (max-width: 990px) {
        width: 40px;
        height: 60px;
    }
}

.middleCard {
    width: 50px;
    height: 70px;
    background-image: url('../public/assets/images/card_top_red.png');
    background-repeat: no-repeat;
    background-size: 90%;

    @media (max-width: 768px) {
        width: 35px;
        height: 55px;
    }
}

.card {
    background-color: #2E2E3A;
}

.seatTexts {
    text-align: center;
    color: #FFFFFF;

    @media (max-width: 990px) {
        font-size: 10px;
    }
}

.lastActionTexts {
    text-align: center;
    color: #FFFFFF;
    font-size: 20px;
}

.totalPotText {
    text-align: center;
    color: #FFFFFF;
    font-size: 30px;
    margin-top: -10px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.minBetText {
    text-align: center;
    color: #FFFFFF;
    font-size: 12px;
    margin-top: -10px;

    @media (max-width: 990px) {
        font-size: 10px;
    }

}

.chipIcon {
    width: 30px;
    height: 30px;
    background-image: url('../public/assets/images/chip_icon.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
    margin-left: -10px;

    @media (max-width: 990px) {
        width: 20px;
        height: 20px;
    }
}

.logoRoundIcon {
    width: 30px;
    height: 30px;
    background-image: url('../public/assets/images/logo_circle.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 5px;
    margin-left: -10px;
}

.moneyView {
    width: 30px;
    height: 30px;
    background-image: url('../public/assets/images/chips_in_icon.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
}

.dealerChipView {
    width: 40px;
    height: 40px;
    background-image: url('../public/assets/images/dealer_chip.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 2;
    position: absolute;
    top: 30px;
    left: 130px;

    @media (max-width: 990px) {
        width: 30px;
        height: 30px;
        left: 120px;
    }
}

.playBadge {
    width: 110px;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    margin-top: 10px;
}

.action-animation {
    opacity: 0;
    animation: fadenum 1.5s;
}

.card-glow {
    animation: slideUp 1.0s infinite alternate;
}

.card-animation {
    animation: winnerPulse 0.5s infinite alternate;
}

.magicFast {
    -webkit-animation-duration: 0.2s;
    -moz-animation-duration: 0.2s;
    -o-animation-duration: 0.2s;
    animation-duration: 0.2s;
}

.slideUpCustom {
    animation: customSlideUp 0.5s forwards;
}

@keyframes customSlideUp {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translateY(-20%);
    }
}


.modal-content {
    width: 500px;
}


@keyframes winnerPulse {
    from {
        box-shadow: 0px 0px 10px 3px #f1f1f1;
    }
    to {
        box-shadow: 0px 0px 40px 5px #f1f1f1;
    }
}


@keyframes slideUp {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }

    20% {
        transform-origin: 0 0;
        transform: translateY(-20%);
    }
}

@keyframes fadenum {
    0% {
        opacity: 0;
        transform-origin: 50% 50%;
        transform: scale(2, 2);
        filter: blur(2px)
    }
    100% {
        opacity: 1;
        transform-origin: 50% 50%;
        transform: scale(1, 1);
        filter: blur(0)
    }
}

@keyframes lineburn {
    from {
        width: 100%
    }
    to {
        width: 0%
    }
}

.ctrl-btn-visible {
    visibility: visible;
}

.ctrl-btn-hide {
    visibility: hidden;
}


/* 3rd party styling */

.btn3d {
    position: relative;
    top: -6px;
    border: 0;
    transition: all 40ms linear;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 2px;
    margin-right: 2px;
}

.btn3d:active:focus,
.btn3d:focus:hover,
.btn3d:focus {
    -moz-outline-style: none;
    outline: medium none;
}

.btn3d:active, .btn3d.active {
    top: 2px;
}

.btn3d.btn-white {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #f5f5f5, 0 8px 8px 1px rgba(0, 0, 0, .2);
    background-color: #fff;
}

.btn3d.btn-white:active, .btn3d.btn-white.active {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
    background-color: #fff;
}

.btn3d.btn-default {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 2px rgba(255, 255, 255, 0.10) inset, 0 8px 0 0 #BEBEBE, 0 8px 8px 1px rgba(0, 0, 0, .2);
    background-color: #f9f9f9;
}

.btn3d.btn-default:active, .btn3d.btn-default.active {
    color: #666666;
    box-shadow: 0 0 0 1px #ebebeb inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, .1);
    background-color: #f9f9f9;
}

.btn3d.btn-primary {
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #4D5BBE, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #4274D7;
}

.btn3d.btn-primary:active, .btn3d.btn-primary.active {
    box-shadow: 0 0 0 1px #417fbd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #4274D7;
}

.btn3d.btn-success {
    box-shadow: 0 0 0 1px #31c300 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #5eb924, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #78d739;
}

.btn3d.btn-success:active, .btn3d.btn-success.active {
    box-shadow: 0 0 0 1px #30cd00 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #78d739;
}

.btn3d.btn-info {
    box-shadow: 0 0 0 1px #00a5c3 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #348FD2, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #39B3D7;
}

.btn3d.btn-info:active, .btn3d.btn-info.active {
    box-shadow: 0 0 0 1px #00a5c3 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #39B3D7;
}

.btn3d.btn-warning {
    box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #D79A34, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #FEAF20;
}

.btn3d.btn-warning:active, .btn3d.btn-warning.active {
    box-shadow: 0 0 0 1px #d79a47 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #FEAF20;
}

.btn3d.btn-danger {
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #AA0000, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #D73814;
}

.btn3d.btn-danger:active, .btn3d.btn-danger.active {
    box-shadow: 0 0 0 1px #b93802 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #D73814;
}

.btn3d.btn-magick {
    color: #fff;
    box-shadow: 0 0 0 1px #9a00cd inset, 0 0 0 2px rgba(255, 255, 255, 0.15) inset, 0 8px 0 0 #9823d5, 0 8px 8px 1px rgba(0, 0, 0, 0.5);
    background-color: #bb39d7;
}

.btn3d.btn-magick:active, .btn3d.btn-magick.active {
    box-shadow: 0 0 0 1px #9a00cd inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 1px 3px 1px rgba(0, 0, 0, 0.3);
    background-color: #bb39d7;
}


/* ------------------------------------------- */
/* Collect pot movement animation */

:root {
    --scale-factor: 1;
}

@media (max-width: 768px) {
    :root {
        --scale-factor: 0.40;
    }
}

@keyframes s1ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(80px * var(--scale-factor)), calc(-120px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}

@keyframes s2ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(190px * var(--scale-factor)), calc(40px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}

@keyframes s3ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(70px * var(--scale-factor)), calc(160px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}

@keyframes s4ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(-60px * var(--scale-factor)), calc(160px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}

@keyframes s5ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(-190px * var(--scale-factor)), calc(40px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}

@keyframes s6ChipsToPot {
    0% {
        transform-origin: 0 0;
        transform: translateY(0%);
    }
    100% {
        transform-origin: 0 0;
        transform: translate3D(calc(-60px * var(--scale-factor)), calc(-120px * var(--scale-factor)), 0); /* Scaled X, Y */
    }
}


/* ------------------------------------------- */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.5);
}

.overlay-content {
    color: white;
    font-size: 4rem;
    animation: fade-in-out 2s ease-in-out forwards;
    background: rgba(0, 0, 0, 0.8);
    padding: 20px 40px;
    border-radius: 15px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    text-align: center;
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
        transform: scale(0.9);
    }
    20% {
        opacity: 1;
        transform: scale(1);
    }
    80% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0.9);
    }
}

.chatSize {
    background-color: #434343;
    min-height: 650px;
    max-height: 650px;
    max-width: 310px;

    @media (max-width: 1200px) {
        min-height: 80vh;
        max-height: 80vh;
    }
}

.tableRow {
    height: 140px;

    @media (max-width: 990px) {
        height: 120px;
    }

    @media (max-width: 768px) {
        height: 80px;
    }
}

.roomStatusText {
    color: #FFFFFF;
    font-size: 12px;

    @media (max-width: 768px) {
        font-size: 8px;
    }
}


.bottleSpinTable {
    position: relative;
    padding: 5px;
    background-image: url('../public/assets/images/round_table_green.png');
    background-repeat: no-repeat;
    background-size: 100%;
    max-width: 500px;
    width: 500px;
    height: 500px;

    @media (max-width: 768px) {
        margin-bottom: 10px;
        max-width: 250px;
        width: 250px;
        height: 250px;
    }
}

.bottleSpinTableRow {
    height: 180px;
}

.spinnerChipView {
    width: 40px;
    height: 40px;
    background-image: url('../public/assets/images/logo_circle.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: -15px;
    z-index: 5;
}

.bottleContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.bottle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bottleTotalPotText {
    position: absolute;
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    background-color: #2E2E3A;
}

.bottleIcon {
    width: 200px;
    height: 200px;
    background-image: url('../public/assets/images/dealer_chip.png');
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: center;

    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}
